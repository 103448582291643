import { RestService } from './rest.service';
import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  public loginStatus = new BehaviorSubject(false);
  public userData = new BehaviorSubject(null);
  public currentUser:any;
  public lang = new BehaviorSubject(null);
  public currentLang: string;

  private readonly USER_DATA_KEY = 'userData';
  private readonly LANG_DATA_KEY = 'langCode';

  constructor(
    private storage: Storage,
    //private restService: RestService
    ) { 
    this.storage.create().then((storage) => {
      
      console.log("Storage created.");
      this._storage = storage;
      this.setupUserStorage();
      this.setupLangStorage();
    })
  }

  setupUserStorage() {
    this._storage.get(this.USER_DATA_KEY).then((userData) => {
      if (userData) {
        console.log("User logged");
        this.userData.next(userData);
        this.loginStatus.next(true);
        this.currentUser = userData;
      } else {
        console.log("User not logged");
        this.removeUserFromStorage();
        this.currentUser = null;
      }
    });
  }

  async storeUserData(userData:any) {
    console.log("Storing user data");
    await this._storage.set(this.USER_DATA_KEY, userData);
    this.userData.next(userData);
    this.loginStatus.next(true);
    this.currentUser = userData;
  }

  async removeUserFromStorage() {
    console.log("Removing user data");
    await this._storage.remove(this.USER_DATA_KEY);
    this.userData.next(null);
    this.loginStatus.next(false);
    this.currentUser = null;
  }

  getLoginStatus() {
    return this.loginStatus.value;
  }

  getUserData() {
    return this.userData;
  }

  

  /// LANG

  setupLangStorage() {
    this._storage.get(this.LANG_DATA_KEY).then((lang) => {
      if (lang) {
        console.log("setupLangStorage lang data", lang);
        this.lang.next(lang);
        this.currentLang = lang;
      }
    });
  }

  async storeLang(lang:string) {
    console.log("Storing lang", lang);
    await this._storage.set(this.LANG_DATA_KEY, lang);
    this.lang.next(lang);
    this.currentLang = lang;
  }

  getLang() {
    return this.lang;
  }
  
}
