import { Injectable } from '@angular/core';

export class LanguageModel {
  name: string;
  code: string;
}

@Injectable()
export class LanguageService {
  languages: Array<LanguageModel> = new Array<LanguageModel>();

   constructor() {
     this.languages.push(
      { name: 'Italiano', code: 'it' },
      { name: 'English', code: 'en' }
     );
   }

   getLanguages() {
     return this.languages;
   }

 }
