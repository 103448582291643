import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { Observable, BehaviorSubject, Subject, from, of } from 'rxjs';
import { ProfileModel } from '../pages/account/profile/profile.model';
import { DataStore } from '../shell/data-store';
import { Capacitor } from '@capacitor/core';
import { StorageService } from './storage.service';

export const apiUrl = environment.apiUrl;
const apiKey = environment.apiKey;

@Injectable({
  providedIn: 'root'
})
export class RestService {

  scritta: string = '/assets/data/scritta_sagoma.json';
  letters: string = '/assets/data/placeholders_all.json';

  

  profileDataStore: DataStore<ProfileModel>;
  redirectResult: Subject<any> = new Subject<any>();


  public config:any;

  constructor(
    private http: HttpClient, 
    private meta: Meta,
    private storageService: StorageService
    ) { 
    
  }

  fetchLetter(letter:string) {
    const letterUrl = '/assets/data/letters/'+letter+'.json';
    return this.http.get(letterUrl);
  }

  fetchScritta() {
    return this.http.get(this.scritta);
  }

  fetchLetters() {
    return this.http.get(this.letters);
  }

  async getConfig() {
    
    if (!this.config) {
      return await new Promise((resolve, reject) => {
        let url = apiUrl+'/custom/getConfig';
        console.log(url);
        this.http.get(url, this.getHeaders())
          .subscribe(response => {
            this.config = response;
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    } else {
      return await new Promise((resolve, reject) => {
        resolve(this.config);
      });
    }
  }

  getGeoLettersAvailability() {
    let url = apiUrl+'/custom/getGeoLettersAvailability';
    console.log(url);
    return this.http.get(url, this.getHeaders());
  }

  getGeoLetter(letter) {
    let url = apiUrl+'/custom/getGeoLetter/'+letter;
    console.log(url);
    
    return this.http.get(url, this.getHeaders());
  }

  getHeaders() {
    let appLang = "it";
    const _currentLang = this.storageService.currentLang;
    if (_currentLang) {
      appLang = _currentLang;
    }
    
    let uid = '';
    const currentUser = this.storageService.currentUser;
    if (currentUser) {
      uid = currentUser.user?.uid;
    }

    let appVersion = '';
    const viewport = this.meta.getTag('name=version');
    if (viewport) { 
      appVersion = viewport.content;
    }

    const myHeaders = { 
      
      headers: new HttpHeaders()
        .set('X-Client', apiKey)
        .set('X-App-User', uid)
        .set('X-App-Version', appVersion)
        .set('X-App-Platform', Capacitor.getPlatform())
        .set('X-Lang', appLang)
    };
    const debugHeaders = {
      'X-Client': apiKey,
      'X-App-User': uid,
      'X-App-Version': appVersion,
      'X-App-Platform': Capacitor.getPlatform(),
      'X-Lang': appLang
    }
    console.log("headers",debugHeaders);
    return myHeaders;
  }

  
  async sendVerificationMail(email: string) {
    let url = apiUrl+'/custom/sendVerificationMail/'+email;
    console.log(url);

    return await new Promise((resolve, reject) => {
      this.http.get(url, this.getHeaders())
        .subscribe(response => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });

  }

  removeUserBooking(email: string ) {
    let url = apiUrl+'/custom/removeUserBooking/'+email;
    console.log(url);

    return new Promise((resolve, reject) => {
      this.http.get(url, this.getHeaders())
        .subscribe(response => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  loginWithEmail(email:string, password:string) {
    let url = apiUrl+'/custom/loginUser';
    console.log(url);

    const data = JSON.stringify({
      email: email,
      password: password
    });

    return new Promise((resolve, reject) => {
      this.http.post(url, data, this.getHeaders())
        .subscribe(response => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  // RegisterUser(email, password) {
  registerWithEmail(userData:any) {
    let url = apiUrl+'/custom/registerUser';
    console.log(url);

    return new Promise((resolve, reject) => {
      this.http.post(url, JSON.stringify(userData), this.getHeaders())
        .subscribe(response => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

  updateUser(userData:any, id:string) {
      let url = apiUrl+'/custom/updateUser/'+id;
      console.log(url);
  
      return new Promise((resolve, reject) => {
        this.http.post(url, JSON.stringify(userData), this.getHeaders())
          .subscribe(response => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    }


  

  public getProfileDataSource(): Observable<ProfileModel> {
    return of(this.setUserModelForProfile());
  }


  private setUserModelForProfile(): ProfileModel {
    const userModel = new ProfileModel();
    //const provierData = this.currentUser.providerData[0];
    //const userData: any = provierData;
    userModel.image = '';
    userModel.name = '', //this._currentUser.displayName || 'What\'s your name?';
    userModel.role = 'How would you describe yourself?';
    userModel.description = 'Anything else you would like to share with the world?';
    userModel.phoneNumber = '', //this._currentUser.phoneNumber || 'Is there a number where I can reach you?';
    userModel.email = '', //this._currentUser.email || 'Where can I send you emails?';
    userModel.provider = 'Credentials';

    return userModel;
  }

  public getProfileStore(dataSource: Observable<ProfileModel>): DataStore<ProfileModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: ProfileModel = new ProfileModel();
    this.profileDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.profileDataStore.load(dataSource);
    return this.profileDataStore;
  }

  public stripePreparePayment(customerData) {
    let url = apiUrl+'/custom/stripePreparePayment';
    console.log(url);

    return new Promise((resolve, reject) => {
      this.http.post(url, JSON.stringify(customerData), this.getHeaders())
        .subscribe(response => {
          resolve(response);
        }, (err) => {
          reject(err);
        });
    });
  }

}
