import { RestService } from './services/rest.service';
import { AlertController, MenuController } from '@ionic/angular';
import { LanguageService } from './services/language.service';
import { StorageService } from './services/storage.service';
import { Component, NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Browser } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Informazioni', url: '/info', icon: 'information-circle' },
    { title: 'Segnaposti', url: '/placeholders', icon: 'map' },
    { title: 'Contatto', url: '/contact', icon: 'mail' }
  ];

  /**
   * DA AGGIUNGERE:
   * Sonazioni
   * https://enappd.com/blog/integrate-stripe-payment-gateway-in-ionic-5-apps-and-pwa-using-firebase/158/
   * 
   * pk_live_51Jx8LjCGuXZEwsmN73pBYE4wAJ6Rp0HLz5ouoSNsryFL4Zp5XZCektxvWdJtx1LjaMDIoDrn2wML7MFZuS7VHAm300P36xFTWY
   */




  userData:any;

  available_languages = [];
  translations;
  
  constructor(
    private storageService: StorageService,
    public translate: TranslateService,
    public languageService: LanguageService,
    public alertController: AlertController,
    public menuController: MenuController,
    private restService: RestService,
    private ngZone: NgZone

  ) { 
    this.init();
    
    this.ngZone.run(() => {
      this.initLanguage();
      this.listenAuth();
    });

    
    
  }

  

  async init() {
    // impostazione di tutto in 2 nsec
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true
    });
  }
  
  listenAuth() {
    this.storageService.getUserData().subscribe(userData => {
      this.userData = userData;
    });
  }

  async openUrl(_url: string) {
    await Browser.open({ url: _url });
  }

  getTranslations() {

    //console.log("this.storageService.currentLang/this.translate.currentLang", this.storageService.currentLang, this.translate.currentLang);
    const lang = this.translate.currentLang;
    this.translate.getTranslation(lang).subscribe((translations) => this.translations = translations);
  }
  
  async initLanguage() {
    
    let lang = 'it';
    try {
      const langCode = await Device.getLanguageCode();
      console.log("device lang:",langCode);
      lang = (langCode.value != 'it-IT') ? 'en' : 'it';
    } catch(e) {
      console.log("Device.getLanguageCode() err:",e);
    }
    
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(lang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);

    // ricarica lingue
    this.getTranslations();

    // se lingua impostata nello storage
    setTimeout(() => {
      this.storageService.getLang().subscribe(lang => {
        if (lang!=null) {
          console.log("* Language from store:",lang)
          this.translate.use(lang);
          this.getTranslations();
        }
        
      });
    }, 500);

    // ascoltatore cambio lingua
    this.translate.onLangChange.subscribe((data) => {
      console.log("Language changed", data)
      this.getTranslations();
    });
  }

  async openLanguageChooser() {
    this.available_languages = this.languageService.getLanguages()
    .map(item =>
      ({
        name: item.name,
        type: 'radio',
        label: item.name,
        value: item.code,
        checked: item.code === this.translate.currentLang
      })
    );

    const alert = await this.alertController.create({
      header: this.l("Scegli la lingua"),
      inputs: this.available_languages,
      cssClass: 'language-alert',
      buttons: [
        {
          text: this.l("CANCEL"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.menuController.toggle();
          }
        }, {
          text: this.l("OK"),
          handler: (data) => {
            if (data) {

              this.changeLanguage(data);
              
              this.menuController.toggle();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  changeLanguage(lang:string) {

    // salvataggio al livello della app
    this.storageService.storeLang(lang);

    // impostazione servizio lingue
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    
    // ricarica traduzioni
    this.getTranslations();

    // aggiorna utente nel db remoto
    if (this.storageService.currentUser) {
      // aggiorno lingua nel database
      const id = this.storageService.currentUser.user?.id;
      this.restService.updateUser({lang: lang}, id);
    }
  }



  l(key:string) {
    if (this.translations) {
      return this.translations[key]
    }
    return key;
  }

}
