// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBkK_z3D6h036nLTo57c-oSnXQif22PEMM",
    authDomain: "stopclimatechange-space.firebaseapp.com",
    projectId: "stopclimatechange-space",
    storageBucket: "stopclimatechange-space.appspot.com",
    messagingSenderId: "183606447862",
    appId: "1:183606447862:web:3cb494d4e5009db4d366d7",
    measurementId: "G-ENNS6SQ4KK"
  },
  appShellConfig: {
    debug: false,
    networkDelay: 500
  },
  "apiKey":"1b4b0e9e-6dd2-4c8b-a8a6-cd8986b326fe",
  "apiUrl":"https://api.stopclimatechange.space",
  "languages": [
    { text: 'Italiano (Italian)', value: 'it', name: 'Italiano' },
    { text: 'English', value: 'en', name: 'English' },
  ],
  "eventDate": "2022-03-26 12:05:00",
  "alteventDate": "2022-04-02 12:05:00",
  "emailto": "info@stopclimatechange.space",
  "stripe_payments": {
    "public_key": "pk_test_51Jx8LjCGuXZEwsmNcXMqCgjfbam9S5pkDs2CRoprQeoH184lTsyk4UzRtuBHVTJShc7A7TnQxDBO0C8pAPa1OwFU00JxLVBfI5"
  }
};

/*

pk_test_51Jx8LjCGuXZEwsmNcXMqCgjfbam9S5pkDs2CRoprQeoH184lTsyk4UzRtuBHVTJShc7A7TnQxDBO0C8pAPa1OwFU00JxLVBfI5


 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
